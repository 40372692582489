import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, GuestGuard, NotAdminGuard, MaintenanceGuard, NotMaintenanceGuard, PendingEmailGuard, PendingGuard, AddressUpdateGuard, StagingGuard } from '@fengbrasil/ngx-fengst-auth';
import { MaintenanceComponent, PageNotFoundComponent, InternalServerErrorComponent, LogoutComponent, TermsComponent, RecoveryPasswordComponent, DiscountValidatorComponent, ScailingMessageComponent, AdminComponent, PrivacyComponent } from '@fengbrasil/ngx-fengst-layout';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePublicModule),
    canActivate: [GuestGuard],
    data: {
      meta: [
        {
          name: 'description',
          content: 'O Sócio Torcedor do Fogão Te Deixa Ainda Mais Perto da Sua Maior Paixão. Viva Experiências Exclusivas no Niltão e Mostre para Todos Como é o Maior Amor do Mundo.'
        },
      ],
    },
  },
  {
    path: 'home',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePrivateModule),
    canActivate: [AuthGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard, AddressUpdateGuard],
  },
  {
    path: 'home/:page',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePublicModule),
    canActivate: [MaintenanceGuard, AddressUpdateGuard],
  },
  {
    path: 'entrar',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-auth').then((m) => m.AuthCognitoModule),
    canActivate: [MaintenanceGuard, GuestGuard],
  },
  {
    path: 'minha-conta/meu-plano',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PlanModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, AddressUpdateGuard],
  },
  {
    path: 'minha-conta/meu-perfil',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ProfileModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, AddressUpdateGuard],
  },
  {
    path: 'minha-conta/pagamentos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PaymentsModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, AddressUpdateGuard],
  },
  {
    path: 'minha-conta/dependentes',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.DependentsModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, AddressUpdateGuard],
  },
  {
    path: 'minha-conta/sorteios',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.RafflesModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard, AddressUpdateGuard],
  },
  {
    path: 'carrinho',
    redirectTo: 'checkout/register',
    pathMatch: 'full'
  },
  {
    path: 'checkout/register',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegisterModule),
    canActivate: [MaintenanceGuard, GuestGuard],
  },
  // {
  //   path: 'checkout/registration-foreigner/subscribe/:idplano',
  //   loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegistrationForeignerModule),
  //   canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard]
  // },
  {
    path: 'checkout/success',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SuccessModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/success-outside',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SuccessOutsideModule),
    canActivate: [MaintenanceGuard, AuthGuard]
  },
  {
    path: 'checkout/subscribe/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/subscribe-outside/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/resubscribe/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.ResubscribeModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/resubscribe/:idplano/:idcontrato',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.ResubscribeContractModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/upgrade/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.UpgradeModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/address',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.AddressModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/address-update',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.AddressUpdateModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/address-outsider',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.AddressOutsiderModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard]
  },
  {
    path: 'checkout/address-outsider-update',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.AddressOutsiderUpdateModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard]
  },
  {
    path: 'checkout/cart/:type/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartModule),
    canActivate: [MaintenanceGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/cart',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartModule),
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'planos',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.PlansModule),
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'login-app',
    loadChildren: () => import('@fengbrasil/ngx-fengst-auth').then(m => m.LoginAppModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'chatbot/sso',
    loadChildren: () => import('@fengbrasil/ngx-fengst-auth').then(m => m.NgxFengSTChatbotSsoModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'sso',
    loadChildren: () => import('@fengbrasil/ngx-fengst-auth').then(m => m.LoginAppModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'internacional',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.PlansOutsideModule),
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'ingressos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-events').then((m) => m.EventsMainModule),
      canActivate: [MaintenanceGuard, PendingGuard],
  },
  {
    path: 'conteudo-exclusivo',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-exclusive-content').then((m) => m.MainModule),
    canActivate: [MaintenanceGuard, PendingGuard],
  },
  {
    path: 'parceiros',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-partner').then((m) => m.PartnerModule),
    canActivate: [MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'experiencias',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.CatalogModule),
    canActivate: [MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'experiencias/como-pontuar',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.HowModule),
    canActivate: [MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'experiencias/extrato',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.PointsModule),
    canActivate: [MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'experiencias/resgatados',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.RescuesModule),
    canActivate: [MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'atendimento',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-contact').then((m) => m.ContactModule),
    canActivate: [MaintenanceGuard, PendingGuard],
  },
  {
    path: 'livreto',
    loadChildren: () =>
    import('@fengbrasil/ngx-fengst-landing').then((m) => m.LandingMainModule),
      canActivate: [MaintenanceGuard],
  },
  {
    path: 'livreto/cadastro',
    loadChildren: () =>
    import('@fengbrasil/ngx-fengst-landing').then((m) => m.LandingRegisterModule),
      canActivate: [MaintenanceGuard],
  },
  {
    path: 'livreto/sucesso',
    loadChildren: () =>
    import('@fengbrasil/ngx-fengst-landing').then((m) => m.LandingSuccessModule),
      canActivate: [MaintenanceGuard],
  },
  {
    path: 'pendente',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-auth').then((m) => m.PendingModule),
    canActivate: [MaintenanceGuard, AuthGuard],
  },
  {
    path: 'validar-cadastro/:id',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ValidateAccountModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'constelacao',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-embassies').then((m) => m.EmbassiesModule),
      canActivate: [MaintenanceGuard],
  },
  {
    path: 'transparencia',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-xray').then((m) => m.XrayModule),
      canActivate: [MaintenanceGuard, PendingGuard],
  },
  // {
  //   path: 'pesquisa',
  //   component: EmbedFormsComponent,
  //   canActivate: [MaintenanceGuard, AuthGuard, PendingGuard],
  // },
  {
    path: 'conquistas-gloriosas',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.WelcomeKitModule),
    canActivate: [MaintenanceGuard, PendingGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [NotAdminGuard],
  },
  {
    path: 'termos-de-uso',
    component: TermsComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'aviso-de-privacidade',
    component: PrivacyComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'sair',
    component: LogoutComponent,
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'WE',
    component: MaintenanceComponent,
    resolve: {
      url: 'externalUrlRedirectResolver',
    },
    data: {
      externalUrl: 'https://admin.camisa7.botafogo.com.br/WE',
    },
  },
  {
    path: 'alta-demanda',
    component: ScailingMessageComponent
  },
  {
    path: '500',
    component: InternalServerErrorComponent
  },
  {
    path: 'manutencao',
    component: MaintenanceComponent,
    canActivate: [NotMaintenanceGuard],
  },
  {
    path: 'consultar-socio',
    component: DiscountValidatorComponent,
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'recuperar-senha/:id',
    component: RecoveryPasswordComponent,
    canActivate: [MaintenanceGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [MaintenanceGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
